import { get } from 'lodash';
import data from 'assets/language/data.json';
import { useRouteData } from './useRouteData';
import { useRouter } from 'next/router';
import { isEnglishDomain } from 'configs';

export const useTranslation = () => {
  const routeData = useRouteData();

  const { asPath } = useRouter();
  const xlocale = asPath.startsWith('/en') ? 'en' : 'ar';
  let locale = routeData?.language || xlocale || 'ar';

  if (isEnglishDomain) {
    locale = 'en';
  }

  const t = (path: string) => {
    return get(data, `${path}.${locale}`) ?? path;
  };

  return { t, locale };
};
