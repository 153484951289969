import { Box, Container, HStack, Stack, VStack } from '@chakra-ui/react';

import React from 'react';
import { HeroContent } from 'components/gutenberg';
import { useRouteData } from 'hooks';
import { AdsSlot } from 'components/ads';
import dynamic from 'next/dynamic';
import LoginModal from 'components/shared/LoginModal';
import { useRouter } from 'next/router';
import MenuSlider from 'components/shared/MenuSlider';
const Footer = dynamic(() => import('../system/footer/Footer'));
const Header = dynamic(() => import('../system/header/index'));
const Sidebar = dynamic(() => import('../system/sidebar/index'));

const DefaultLayout: React.FC = ({
  children,
  hasClosingButtonPlace = false,
  isFullWidth = false
}: any) => {
  const data = useRouteData();
  const router = useRouter();
  const isBlogPage = router.asPath.includes('blog');

  return (
    <>
      <Container
        maxW='container.xl'
        centerContent
        pb={8}
        paddingInlineStart={isBlogPage ? '0px' : 4}
        paddingInlineEnd={isBlogPage ? '0px' : 4}
      >
        <Stack
          align={'flex-start'}
          direction={{ base: 'column', md: 'column', lg: 'row', xl: 'row' }}
          spacing={{ base: 0, lg: 3, xl: 3 }}
          position='relative'
          w='100%'
        >
          <Header hasClosingButtonPlace={hasClosingButtonPlace} />
          <VStack
            align={'stretch'}
            spacing={{ base: 4, lg: 6, xl: 6 }}
            flexBasis='100%'
            maxW='full'
            width={{
              base: isBlogPage ? '100%' : '98%',
              md: '96%',
              lg: '86%',
              xl: '80%'
            }}
          >
            {data?.type === 'page' && data?.acf?.display_ad && (
              <AdsSlot place='header' />
            )}
            {data?.object_type === 'post' && data?.type !== 'page' && (
              <AdsSlot place='header' />
            )}
            {!isFullWidth &&
              data?.object_type === 'post' &&
              data?.type !== 'page' && (
                <MenuSlider homePage={data?.type === 'page' ? true : false} />
              )}
            <HeroContent />
            <HStack
              align={'flex-start'}
              position='relative'
              w='full'
              spacing={{ base: 0, md: 3, lg: 6, xl: 20 }}
              direction={{ base: 'column', md: 'column', lg: 'row', xl: 'row' }}
              mt={data?.type === 'blog' ? '0 !important' : '2 !important'}
            >
              <Box
                mt={data?.type === 'blog' && '0 !important'}
                as='main'
                maxWidth={{
                  base: 'full',
                  lg: '50%',
                  xl: '62%'
                }}
                flexShrink={0}
                flexBasis='100%'
                w='full'
                pr={{ base: 0, lg: hasClosingButtonPlace ? 4 : 0 }}
              >
                {children}
              </Box>
              {!isFullWidth && <Sidebar />}
            </HStack>
          </VStack>
        </Stack>
      </Container>
      <Footer />
      <LoginModal />
    </>
  );
};

export default DefaultLayout;
