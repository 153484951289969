import { HStack, Icon, IconButton } from '@chakra-ui/react';
import { Avatar } from 'components/shared';
import { NextChakraLink } from 'components/wrappers';
import { isEnglishDomain } from 'configs';
import { useTranslation } from 'hooks';
import React from 'react';
import {
  FaFacebookF,
  FaInstagram,
  FaTiktok,
  FaTwitter,
  FaYoutube
} from 'react-icons/fa';

type SocialLink = {
  title: string;
  url: string;
  icon: any;
};

interface Props {
  author: any;
  hasNoSocial?: boolean;
}

export const AuthorInfo: React.FC<Props> = ({
  author,
  hasNoSocial,
  increaseWidth
}) => {
  const { t, locale } = useTranslation();
  if (!author) {
    return null;
  }

  const { avatar_urls, name, link, user_social_links } = author;
  const { facebook, instagram, tiktok, twitter, youtube } = user_social_links;
  const imageSrc = avatar_urls?.[48] ?? avatar_urls?.[24];

  const items: SocialLink[] = [
    {
      title: t('general.forms.facebook'),
      url: facebook,
      icon: FaFacebookF
    },
    {
      title: t('general.forms.instagram'),
      url: instagram,
      icon: FaInstagram
    },
    {
      title: t('general.forms.tiktok'),
      url: tiktok,
      icon: FaTiktok
    },
    {
      title: t('general.forms.youtube'),
      url: youtube,
      icon: FaYoutube
    },
    {
      title: t('general.forms.twitter'),
      url: twitter ? `https://twitter.com/${twitter}` : '',
      icon: FaTwitter
    }
  ];
  const xlink =
    locale === 'ar'
      ? link
      : isEnglishDomain
      ? link
      : link.replace('/author/', '/en/author/');

  return (
    <HStack align={'center'}>
      <Avatar name={name} src={imageSrc} increaseWidth={increaseWidth} />
      <NextChakraLink href={xlink}>{name}</NextChakraLink>
      {!hasNoSocial &&
        items
          .filter(({ url }) => Boolean(url))
          .map(({ icon, url, title }, index) => (
            <IconButton
              as='a'
              icon={<Icon as={icon} />}
              size='xs'
              key={index}
              href={url}
              isRound
              target='_blank'
              rel='noopener noreferrer'
              aria-label={`${t('bio.link')} ${title}`}
            />
          ))}
    </HStack>
  );
};
