import type { GetStaticPaths, GetStaticProps } from 'next';
import { getRevalidateTime, getRouteData } from 'utls';
import dynamic from 'next/dynamic';
import { isEnglishDomain } from 'configs';
const PostTypeRenderer = dynamic(
  () => import('../components/postTypes/PostTypeRenderer')
);
export default function Page() {
  return <PostTypeRenderer />;
}
export const getStaticProps: GetStaticProps = async context => {
  try {
    const { slug } = context?.params || {};
    const path = `/${slug?.join('/') ?? ''}`;
    let locale = path.startsWith('/en') ? 'en' : 'ar';
    let url =
      path === '/en'
        ? '/'
        : path.startsWith('/en')
        ? path.replace('/en', '')
        : path;

    if (isEnglishDomain) {
      locale = 'en';
      url = path;
    }

    console.log('isEnglishDomain', isEnglishDomain);
    console.log(url);
    console.log(locale);
    console.log(path);
    const data = await getRouteData(url, locale);

    const revalidate = getRevalidateTime(data, path);

    return {
      props: data,
      revalidate
    };
  } catch (e) {
    console.log(e);
    return {
      notFound: true
    };
  }
};

export const getStaticPaths: GetStaticPaths = async () => {
  return { paths: [], fallback: 'blocking' };
};
