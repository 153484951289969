import type { IconButtonProps } from '@chakra-ui/react';
import { HStack, Icon, IconButton, useClipboard } from '@chakra-ui/react';
import { useAuth } from 'context';
import { useBookmark, useTranslation } from 'hooks';
import { useEffect, useState } from 'react';
import { FaFacebook, FaLinkedin, FaTwitter, FaWhatsapp } from 'react-icons/fa';
import BookmarkButton from './BookmarkButton';
import { isEnglishDomain } from 'configs/envs';
import { isNewsDomain } from 'configs/envs';

interface Props {
  link: string;
  title: string;
  id?: any;
}

const sharedProps: IconButtonProps = {
  as: 'a',
  size: 'md',
  variant: 'ghost',
  target: '_blank',
  rel: 'noopener noreferrer',
  _hover: { background: 'light.500' }
};

export const ShareMenu: React.FC<Props> = ({ id, link, title }) => {
  const { t } = useTranslation();
  const pageTitle = title?.rendered;

  const [isBooked, setIsBooked] = useState(null);
  const callBack = ({ data }) => {
    setIsBooked(data);
  };
  const { mutateIsBookMarked } = useBookmark(id, callBack);
  const { token } = useAuth();
  useEffect(() => {
    if (id && token) mutateIsBookMarked(id);
  }, [id, token, isBooked]);

  return (
    <HStack spacing={1} py='1'>
      {!isEnglishDomain && !isNewsDomain && (
        <BookmarkButton isBooked={isBooked} id={id} setIsBooked={setIsBooked} />
      )}
      <IconButton
        {...sharedProps}
        href={`https://www.facebook.com/sharer/sharer.php?u=${link}`}
        icon={<Icon as={FaFacebook} />}
        aria-label={t('bio.share_on_facebook')}
        colorScheme={'facebook'}
      />
      <IconButton
        {...sharedProps}
        href={`https://twitter.com/intent/tweet?text=${pageTitle} ${link}`}
        icon={<Icon as={FaTwitter} />}
        aria-label={t('bio.share_on_twitter')}
        colorScheme={'twitter'}
      />
      <IconButton
        {...sharedProps}
        href={`https://api.whatsapp.com/send?text=${pageTitle} ${link}`}
        icon={<Icon as={FaWhatsapp} />}
        aria-label={t('bio.share_on_whatsapp')}
        colorScheme={'whatsapp'}
      />
      <IconButton
        {...sharedProps}
        href={`https://www.linkedin.com/shareArticle?mini=true&url=${link}`}
        icon={<Icon as={FaLinkedin} />}
        aria-label={t('bio.share_on_linkedin')}
        colorScheme={'linkedin'}
      />
    </HStack>
  );
};
