import React, { useEffect, useState, createContext, useContext } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';

import { useSiteConfig } from 'hooks';
import { dfp } from 'utls';

export const SiteConfigContext = createContext({});
const SiteConfigProvider: React.FC = ({ children }) => {
  const { adsSlots } = useSiteConfig();
  const [isLoading, setIsLoading] = useState(true);
  const router = useRouter();

  // Create ad slots
  useEffect(() => {
    setIsLoading(true);

    dfp.createSlots(adsSlots, true);

    setIsLoading(false);

    const handleRouteChangeStart = url => {
      if (window.location.pathname !== url) {
        setIsLoading(true);
        dfp.removeSlots();
        dfp.createSlots(adsSlots, true);
      }
    };

    const handleRouteChangeComplete = () => {
      setIsLoading(false);
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [adsSlots]);

  const initZohoForm = () => {
    try {
      // Remove existing iframe if any
      const existingForm = document
        .getElementById('zf_div_sghMYi4z5PnvbSorqoJ3-NRpE6mSP2mm1UlbUfgMCXo')
        ?.querySelector('iframe');
      if (existingForm) {
        existingForm.remove();
      }

      const f = document.createElement('iframe');
      f.src =
        'https://forms.zohopublic.com/arageek1/form/Contactus/formperma/sghMYi4z5PnvbSorqoJ3-NRpE6mSP2mm1UlbUfgMCXo?zf_rszfm=1';
      f.style.border = 'none';
      f.style.height = '2200px';
      f.style.width = '100%';
      f.style.transition = 'all 0.5s ease';
      f.setAttribute('aria-label', '\x231\uFE0F\u20E3\x20youth\x20blog\x20💪🏽');

      const d = document.getElementById(
        'zf_div_sghMYi4z5PnvbSorqoJ3-NRpE6mSP2mm1UlbUfgMCXo'
      );
      if (d) {
        d.appendChild(f);
      }
    } catch (e) {
      console.error('Error initializing Zoho form:', e);
    }
  };

  // Add route change handler for Zoho form
  useEffect(() => {
    // Initialize on mount
    initZohoForm();

    // Reinitialize on route change
    router.events.on('routeChangeComplete', initZohoForm);

    return () => {
      router.events.off('routeChangeComplete', initZohoForm);
    };
  }, []);

  return (
    <>
      <SiteConfigContext.Provider value={{ isLoading, adsSlots }}>
        {children}
      </SiteConfigContext.Provider>
      <Script
        src='https://securepubads.g.doubleclick.net/tag/js/gpt.js'
        async
      />

      {/* ZOho Form */}
      <Script id='youth-form' strategy='afterInteractive'>
        {`window.addEventListener('message', function (){
          var evntData = event.data;
          if( evntData && evntData.constructor == String ){
            var zf_ifrm_data = evntData.split("|");
            if ( zf_ifrm_data.length == 2 || zf_ifrm_data.length == 3 ) {
              var zf_perma = zf_ifrm_data[0];
              var zf_ifrm_ht_nw = ( parseInt(zf_ifrm_data[1], 10) + 15 ) + "px";
              var iframe = document.getElementById("zf_div_sghMYi4z5PnvbSorqoJ3-NRpE6mSP2mm1UlbUfgMCXo").getElementsByTagName("iframe")[0];
              if ( iframe && (iframe.src).indexOf('formperma') > 0 && (iframe.src).indexOf(zf_perma) > 0 ) {
                var prevIframeHeight = iframe.style.height;
                var zf_tout = false;
                if( zf_ifrm_data.length == 3 ) {
                  iframe.scrollIntoView();
                  zf_tout = true;
                }
                if ( prevIframeHeight != zf_ifrm_ht_nw ) {
                  if( zf_tout ) {
                    setTimeout(function(){
                      iframe.style.height = zf_ifrm_ht_nw;
                    },500);
                  } else {
                    iframe.style.height = zf_ifrm_ht_nw;
                  }
                }
              }
            }
          }
        }, false);`}
      </Script>
    </>
  );
};

export default SiteConfigProvider;
export const useAdPlace = (adPlace: string) => {
  const { adsSlots, isLoading } = useContext(SiteConfigContext);
  const ad = adsSlots?.find(({ place }) => place === adPlace);

  return {
    ...ad,
    isLoading
  };
};
