import { Box, Flex, HStack, Icon } from '@chakra-ui/react';
import { FaRegStar, FaStar } from 'react-icons/fa';

export const StarRating = ({ max = 5, value }) => {
  const parsedValue = Number(value);
  return (
    <Box>
      <Flex display='inline-flex' pos={'relative'}>
        <HStack spacing={1}>
          {Array(max)
            .fill('')
            .map((_, i) => {
              return (
                <Icon
                  key={i}
                  as={parsedValue - 1 >= i ? FaStar : FaRegStar}
                  boxSize={{ base: 3, sm: 4 }}
                  color='light.900'
                />
              );
            })}
        </HStack>
      </Flex>
    </Box>
  );
};
