import { Box, HStack, Image, Text } from '@chakra-ui/react';
import { useMenu } from 'hooks';
import React from 'react';
import { Slider } from './Slider';
import { NextChakraLink } from 'components/wrappers';
import { useTranslation } from 'hooks';
import type { SlideT } from 'types/menuSlider';

const MenuSlider = () => {
  const { t } = useTranslation();
  const MainMenu = useMenu('main-menu');
  if (!Array.isArray(MainMenu)) return null;

  return (
    <HStack
      align={'center'}
      spacing={0}
      width={'100%'}
      mt='0 !important'
      px='1'
      justifyContent={'flex-start'}
      position={{ base: 'sticky', md: 'initial' }}
      top={{ base: 'calc(100px - 50px)', md: '' }}
      zIndex='999'
      backgroundColor='white'
    >
      <Box
        w={'full'}
        overflowX={'hidden'}
        sx={{
          '&::-webkit-scrollbar': {
            display: 'none'
          },
          '&::-webkit-scrollbar-track': {
            display: 'none'
          },
          '&::-webkit-scrollbar-thumb': {
            display: 'none'
          }
        }}
      >
        <Slider gap={4} hideBar={true}>
          {MainMenu?.map(item => (
            <Slide key={item.id} {...item} />
          ))}
        </Slider>
      </Box>
    </HStack>
  );
};

const Slide: React.FC = ({ url, title, file, icon }: SlideT) => {
  return (
    <Box
      pos='relative'
      flexGrow={0}
      flexShrink={0}
      flexBasis={{ base: '50%', sm: '35%', md: '16%' }}
    >
      <NextChakraLink
        borderRadius={'50px'}
        href={url}
        color='black'
        textDecoration='none'
        _hover={{
          textDecoration: 'none',
          backgroundColor: '#DFDABC',
          borderBottom: 'none'
        }}
        bg='#f1efe2b3'
        display={'flex'}
        justifyContent={'center'}
        gap={2}
        alignItems={'center'}
        py='2'
        px='3'
        sx={{
          textWrap: 'nowrap',
          ':before': {
            backgroundColor: 'unset !important'
          }
        }}
      >
        {file && typeof file === 'string' ? (
          <Image src={file} w={4} h={4} display='inline' />
        ) : (
          <Text>{icon}</Text>
        )}
        {title}
      </NextChakraLink>
    </Box>
  );
};
export default MenuSlider;
