import {
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Input,
  Text,
  VStack
} from '@chakra-ui/react';
import { Message } from 'iconsax-react';
import { useEffect, useState } from 'react';
import axios from 'redaxios';

import { BACKENDURL, htmlParser } from 'utls';
import { useComments, useToast } from 'hooks';
import { CommentsList } from 'components/questions';
import { useSession } from 'next-auth/react';
import { useTranslation } from 'hooks';
import { isEnglishDomain, isNewsDomain } from 'configs';

export const PostMetaFooter = ({ title, link, id, SSRComments }) => {
  const toast = useToast();
  const [email, setEmail] = useState('');
  const { data: session } = useSession;
  const { t } = useTranslation();

  const subscribe = async () => {
    await axios
      .post(`${BACKENDURL}/arageek/v1/newsletter-subscribe`, { email })
      .then(res => {
        toast({
          title: t('general.toastMessages.thanks'),
          description: t('general.toastMessages.subscribed_arageek')
        });
        setEmail('');
      })
      .catch(err => {
        // Handle error
        toast({
          title: t('general.toastMessages.error'),
          description: t('general.toastMessages.error_message'),
          status: 'error'
        });
      });
  };

  const submit = email => {
    if (!email) {
      toast({
        title: t('general.toastMessages.error'),
        description: t('general.toastMessages.add_email'),
        status: 'error'
      });
    } else {
      subscribe();
    }
  };

  const { refetch: commentsRefetch, comments } = useComments(id);

  useEffect(() => {
    commentsRefetch();
  }, [id, session?.accessToken]);
  return (
    <>
      <VStack
        align={'stretch'}
        spacing='4'
        borderTop='1px '
        borderBottom='1px '
        borderColor={'gray.300'}
        py='8'
        my='8'
      >
        <Text fontSize={'lg'} fontWeight='600'>
          {t('general.metaFooter.our_best')}
        </Text>
        <HStack spacing={4} pt='4'>
          <Input
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder={t('general.metaFooter.email')}
            size='lg'
            bg='#fff'
            p='4'
            borderRadius={'md'}
            border='0.5px solid #DDD'
            paddingInlineStart='2'
            fontSize='md'
          />
          <Button colorScheme={'dark'} onClick={() => submit(email)}>
            {t('general.metaFooter.register')}
          </Button>
        </HStack>
        <Text fontSize={'13px'} color='dark.600'>
          {t('general.metaFooter.privacy')}
        </Text>
      </VStack>

      {!isEnglishDomain && !isNewsDomain && (
        <VStack
          as='section'
          align={'stretch'}
          spacing={2}
          bgColor='light.500'
          rounded='md'
          mt='6'
        >
          <VStack
            align={'stretch'}
            bgColor='light.500'
            px='6'
            py='4'
            rounded='md'
            spacing={4}
            id='comments'
          >
            <Heading
              pt='3'
              as='h2'
              fontSize={'h2'}
              data-toc='exclude'
              display={'flex'}
              alignItems='center'
            >
              <Icon as={Message} color='dark.600' marginInlineEnd={1} />
              <Text as='span'>{t('general.metaFooter.your_opinion')} </Text>
            </Heading>
            <Text color='dark.600'>{t('general.metaFooter.take_care')}</Text>
          </VStack>

          {/* TODO clean this code  */}
          <Box display={'none'}>
            {SSRComments?.map((comment, index) => {
              return (
                <Text key={index}>
                  {htmlParser(comment?.content?.rendered)}
                </Text>
              );
            })}
          </Box>

          <Box bgColor='#fdfcf985' p='4' pt='6'>
            <CommentsList
              {...{
                answerId: id,
                blogId: id,
                comments,
                SSRComments,
                commentsRefetch
              }}
            />
          </Box>
        </VStack>
      )}
    </>
  );
};
