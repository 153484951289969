import { Box, VStack } from '@chakra-ui/react';

import { AdsSlot } from 'components/ads';
import { SidebarMenu, SidebarSearch } from './components';

const Sidebar: React.FC = () => {
  return (
    <>
      <Box
        as='aside'
        flexGrow={1}
        position={'sticky'}
        top={4}
        flexBasis='0%'
        w={{ lg: '30%', xl: 'full' }}
        display={{ base: 'none', md: 'none', lg: 'block', xl: 'block' }}
      >
        <VStack
          align={'stretch'}
          minH={{ md: 'calc(100vh - 48px)' }}
          justify={'space-between'}
        >
          <SidebarSearch />
          <AdsSlot place='sidebar' />
          <VStack align={'stretch'}></VStack>
          <SidebarMenu />
        </VStack>
      </Box>
    </>
  );
};
export default Sidebar;
